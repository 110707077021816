import React, { useState } from "react";
import PropTypes from "prop-types";
import "./chunkyButton.scss";

const ChunkyButton = ({
  backgroundColor,
  shadowColor,
  stretch,
  handleClick,
  children,
  isDisabled,
  className,
  isPressed: isPressedExternal = false
}) => {
  const [isInternalPressed, setIsInternalPressed] = useState(false);
  const isPressed = isInternalPressed || isPressedExternal;

  return (
    <div
      className={`button-wrapper ${isPressed ? "is-pressed" : ""}${
        className ? " " + className : ""
      }`}
      onClick={
        !isDisabled
          ? handleClick
          : () => {
              return;
            }
      }
      onMouseUp={() => setIsInternalPressed(false)}
      onMouseDown={() => setIsInternalPressed(true)}
      onTouchStart={() => setIsInternalPressed(true)}
      onTouchEnd={() => setIsInternalPressed(false)}
      style={{
        flexGrow: `${stretch ? 1 : 0}`
        // opacity: isDisabled ? 0.5 : 1,
      }}
    >
      <div
        style={{
          background: backgroundColor
        }}
        className="chunky-button"
      >
        {children}
      </div>
      <div
        className="button-shadow"
        style={{
          background: shadowColor
        }}
      ></div>
    </div>
  );
};

ChunkyButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  shadowColor: PropTypes.string,
  isDisabled: PropTypes.bool,
  stretch: PropTypes.bool,
  isPressed: PropTypes.bool
};

export default ChunkyButton;
